<template>
    <div style="background: #ffffff !important">
        <div class="box">
            <div></div>
            <div class="title">注册结果</div>
            <img @click="all('/Home')" src="../assets/icon／close@3x.png" alt="" />
        </div>
        <div class="container">
            <div class="top">
                <img src="../assets/login_img_zhuchechenggong@3x.png" alt="" />
                <h3>注册成功</h3>
                <p>普通用户身份注册成功，是否进行用户认证？</p>
                <div class="btn">
                    <el-button type="primary" @click="all('/enterprise')">机构认证</el-button>
                    <el-button type="info" @click="all('/personal')">个人认证</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="less" scoped>
/deep/ .el-button {
    height: 36px;
    line-height: 6px;
    border-radius: 4px;
    margin: 50px 10px 0 22px;
}
.box {
    width: 900px;
    height: 60px;
    .flex();
    background: linear-gradient(to right, #ffffff 0%, rgba(520, 226, 200, 0.2) 50%, #ffffff 100%);
    justify-content: space-between;
    box-shadow: -2px 0px 0px 0px #ff8700;
    margin: auto;
    .title {
        font-weight: 500;
        color: #ff8700;
        font-size: 16px;
    }
    img {
        width: 24px;
        height: 24px;
        z-index: 1;
    }
}
.container {
    display: flex;
    align-items: center;
    .top {
        margin: 0 auto;
        img {
            width: 120px;
            height: 120px;
            padding-left: 68px;
        }
        h3 {
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            line-height: 28px;
            margin: 24px 0 8px;
            padding-left: 93px;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #939599;
            line-height: 24px;
        }
    }
}
</style>
